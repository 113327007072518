import React, { useCallback, useState } from 'react'
import { FieldArray, Form, Formik } from 'formik'
import { debounce } from 'lodash'
import { StyleSheet } from 'aphrodite'
import Button from './common/Button'
import Input from './common/Input'
import Card from './common/Card'
import { format } from '../utils/number'
import { useLoad } from '../hooks/request'
import { PRODUCT_DETAIL, PRODUCT_LIST, SUPPLIER_LIST } from '../urls'
import Select from './common/Select'
import { currency, getMeasurement, paid, paymentMethod } from '../utils/types'
import Table from './common/Table'
import { required } from '../utils/validators'
import { useModal } from '../hooks/modal'
import DeleteModal from './common/DeleteModal'
import DocumentsProductUpdate from './DocumentsProductUpdate'
import DocumentsProductCreate from './DocumentsProductCreate'
import NewSupplierSelect from './common/NewSupplier'

export default function DocumentsForm({ loading, onCancel, onSubmit, initialValues = {} }) {
    const [params, setParams] = useState({})
    const productLoad = useLoad({ url: PRODUCT_LIST, params }, [params])
    const productItem = productLoad.response ? productLoad.response.results : []
    const products = productItem.map((item) => ({
        ...item,
        quantity: '',
        count: item?.count || '',
        price: item?.price || '',
        priceSale: item?.priceSale || '',
        priceTransfer: item?.priceTransfer || '',
    }))

    const suppliers = useLoad({ url: SUPPLIER_LIST, params: { size: 1000 } })

    const [showProductCreate, hideProductCreate] = useModal((arrayHelpers) => (
        <DocumentsProductCreate
            onCancel={() => hideProductCreate()} refresh={productLoad.request} arrayHelpers={arrayHelpers} />
    ), styles.modal)
    const [showProductEdit, hideProductEdit] = useModal((props) => (
        <DocumentsProductUpdate onCancel={() => hideProductEdit()} refresh={productLoad.request} item={props.item}
            arrayHelp={props.arrayHelp} />
    ))

    const [showDeleteProduct, hideDeleteProduct] = useModal((item) => (
        <DeleteModal onCancel={() => hideDeleteProduct()} refresh={productLoad.request}
            url={PRODUCT_DETAIL.replace('{id}', item?.id)} name={item?.name} />
    ))

    const debouncedSearch = debounce((search) => setParams((prev) => ({ ...prev, search })), 500)
    const handleSearch = useCallback(({ target: { value } }) => debouncedSearch(value), [debouncedSearch])

    const handleSubmit = (vals, actions) => {
        const data = {
            ...vals,
            dateReturn: vals.dateReturn || undefined,
            invoice: vals.invoice ? +vals.invoice : undefined,
            wholesale: vals.wholesale ? +vals.wholesale : undefined,
            markup: vals.markup ? +vals.markup : undefined,
            vat: vals.vat ? +vals.vat : undefined,
            expenses: vals.expenses ? +vals.expenses : undefined,
        }
        onSubmit(data, actions)
    }

    return (
        <Formik
            enableReinitialize
            onSubmit={handleSubmit}
            initialValues={{
                products: JSON.parse(localStorage.getItem('items')) || [],
                supplier: JSON.parse(localStorage.getItem('formData'))?.supplier || '',
                dateReturn: '',
                currency: JSON.parse(localStorage.getItem('formData'))?.currency || '',
                paid: '',
                paymentMethod: '',
                invoice: '',
                wholesale: '',
                markup: '',
                sales: '',
                margin: '',
                vat: '',
                note: '',
                expenses: '',
                ...initialValues,
            }}
        >
            {({ values, setFieldValue }) => {
                const totals = values.products.reduce(
                    (acc, product) => {
                        const quantity = product.quantity ? +product.quantity : 0
                        const price = product.price ? +product.price : 0
                        const priceSale = product.priceSale ? +product.priceSale : 0
                        acc.totalQuantity += quantity
                        acc.totalPurchaseSum += quantity * price
                        acc.totalSaleSum += quantity * priceSale
                        return acc
                    },
                    { totalQuantity: 0, totalPurchaseSum: 0, totalSaleSum: 0 },
                )

                return (
                    <Form>
                        <div style={{ padding: '1rem', boxShadow: '0px 0px 1px 0px rgba(0,0,0,0.75)' }}>
                            <div className="columns">
                                <div className="column">
                                    <Input disabled placeholder="Zapsklad" name="name" label="Торговая точка*" />
                                </div>

                                <div className="column mt-5">
                                    <NewSupplierSelect
                                        onChange={(e) => {
                                            setFieldValue('supplier', e?.id)
                                            const formData = JSON.parse(localStorage.getItem('formData')) || {}
                                            formData.supplier = e?.id
                                            localStorage.setItem('formData', JSON.stringify(formData))
                                        }}
                                        value={suppliers?.response?.results?.find((item) => item.id === values?.supplier)}
                                        label="Поставщик*"
                                        validate={required}
                                        empty
                                        name="supplier"
                                        options={suppliers?.response?.results}
                                        placeholder="Выбор поставщика"
                                        disabled={loading}
                                    />
                                </div>
                                <div className="column">
                                    <Input name="dateReturn" label="Дата возврата" type="number" disabled={loading} />
                                </div>
                                <div className="column">
                                    <Select
                                        options={currency}
                                        optionValue="value"
                                        name="currency"
                                        label="Валюта*"
                                        disabled={JSON.parse(localStorage.getItem('items'))?.length > 0}
                                        onChange={(e) => {
                                            const selectedCurrency = e.target.value
                                            setFieldValue('currency', selectedCurrency)
                                            setFieldValue('products', [])
                                            const formData = JSON.parse(localStorage.getItem('formData')) || {}
                                            formData.currency = selectedCurrency
                                            localStorage.setItem('formData', JSON.stringify(formData))
                                        }}
                                    />
                                </div>

                                <div className="column">
                                    <Select options={paid} optionValue="value" name="paid" label="Оплачено*" disabled={loading} />
                                </div>
                                <div className="column">
                                    <Select options={paymentMethod} optionValue="value" name="paymentMethod" label="Способ оплаты*" disabled={loading} />
                                </div>
                            </div>

                            <div className="columns">
                                <div className="column">
                                    <Input step="0.01" type="number" name="invoice" label="№ Накладной" disabled={loading} />
                                </div>

                                <div className="column">
                                    <Input step="0.01" type="number" name="wholesale" label="Наценка оптовая" disabled={loading} />
                                </div>
                                <div className="column">
                                    <Input step="0.01" type="number" name="markup" label="Наценка продажная" disabled={loading} />
                                </div>

                                <div className="column">
                                    <Input step="0.01" type="number" name="vat" label="НДС" disabled={loading} />
                                </div>

                                <div className="column">
                                    <Input name="note" label="Примечание" disabled={loading} />
                                </div>
                                <div className="column">
                                    <Input step="0.01" type="number" name="expenses" label="Расходы" disabled={loading} />
                                </div>
                            </div>
                        </div>
                        <br />

                        <Card>
                            <FieldArray
                                name="products"
                                render={(arrayHelpers) => {
                                    console.log('values')

                                    return (
                                        <>
                                            <Table
                                                showEmptyMessage={false}
                                                columns={{
                                                    name: 'Наименование товара',
                                                    remains: 'Остаток',
                                                    quantity: 'Количество',
                                                    measurement: 'Ед. изм.',
                                                    price: 'Цена поступление',
                                                    price_transfer: 'Оптом цена',
                                                    price_sale: 'Цена за единицу',
                                                    summ: 'Сумма',
                                                    actions: '',
                                                }}
                                                items={values.products}
                                                renderItem={(item, index) => {
                                                    const productCurrency = values.products?.[index]?.paymentType
                                                    const price = productCurrency === 'sum' ? item?.price : item?.price
                                                    const count = item?.quantity

                                                    const bodyPrice = productCurrency === 'sum' ? values.products?.[index]?.sumPrice : values.products?.[index]?.usdPrice
                                                    const SalePrice = productCurrency === 'sum' ? values.products?.[index]?.sumPriceSale : values.products?.[index]?.usdPriceSale
                                                    // const Sale = values.products?.[index]?.priceSale
                                                    const SaleOptomPrice = productCurrency === 'sum' ? values.products?.[index].sumPriceTransfer : values.products?.[index].usdPriceTransfer
                                                    // const SaleOptom = values.products?.[index].priceTransfer
                                                    const sum = format(count * price)

                                                    return (
                                                        <tr key={item.id}>
                                                            <td>{item.name}</td>
                                                            <td>{item.count}</td>
                                                            <td>
                                                                <Input
                                                                    type="number"
                                                                    validate={required}
                                                                    className="is-size-7"
                                                                    name={`products[${index}].quantity`}
                                                                    value={item.quantity || ''}
                                                                    onChange={(value) => {
                                                                        const newQuantity = value
                                                                        const parsedQuantity = parseFloat(newQuantity) || 0
                                                                        setFieldValue(`products[${index}].quantity`, parsedQuantity)
                                                                        const localItems = JSON.parse(localStorage.getItem('items')) || []
                                                                        const updatedItems = localItems.map((product) => (product.id === item.id ? { ...product, quantity: parsedQuantity } : product))
                                                                        localStorage.setItem('items', JSON.stringify(updatedItems))
                                                                    }}
                                                                    disabled={loading}
                                                                />
                                                            </td>
                                                            <td>{getMeasurement(item.measurement)}</td>
                                                            <td>
                                                                <Input
                                                                    value={bodyPrice}
                                                                    type="number"
                                                                    validate={required}
                                                                    className="is-size-7"
                                                                    name={`products[${index}].price`}
                                                                    disabled={loading}
                                                                    onChange={(value) => {
                                                                        const newField = item?.paymentType === 'sum' ? 'sumPrice' : 'usdPrice'
                                                                        setFieldValue(`products[${index}].${newField}`, value)
                                                                        const localItems = JSON.parse(localStorage.getItem('items')) || []
                                                                        const updatedItems = localItems.map((product) => (product.id === item.id ? { ...product, [newField]: value } : product))
                                                                        localStorage.setItem('items', JSON.stringify(updatedItems))
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Input
                                                                    value={SaleOptomPrice}
                                                                    type="number"
                                                                    className="is-size-7"
                                                                    validate={required}
                                                                    name={`products.${index}.priceTransfer`}
                                                                    disabled={loading}
                                                                    onChange={(value) => {
                                                                        const newField = item?.paymentType === 'sum' ? 'sumPriceTransfer' : 'usdPriceTransfer'
                                                                        setFieldValue(`products[${index}].${newField}`, value)
                                                                        const localItems = JSON.parse(localStorage.getItem('items')) || []
                                                                        const updatedItems = localItems.map((product) => (product.id === item.id ? { ...product, [newField]: value } : product))
                                                                        localStorage.setItem('items', JSON.stringify(updatedItems))
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Input
                                                                    value={SalePrice}
                                                                    type="number"
                                                                    className="is-size-7"
                                                                    validate={required}
                                                                    name={`products.${index}.priceSale`}
                                                                    disabled={loading}
                                                                    onChange={(value) => {
                                                                        const newField = item?.paymentType === 'sum' ? 'sumPriceSale' : 'usdPriceSale'
                                                                        setFieldValue(`products[${index}].${newField}`, value)
                                                                        const localItems = JSON.parse(localStorage.getItem('items')) || []
                                                                        const updatedItems = localItems.map((product) => (product.id === item.id ? { ...product, [newField]: value } : product))
                                                                        localStorage.setItem('items', JSON.stringify(updatedItems))
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>{sum ?? ''}</td>
                                                            <td>
                                                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                                                    <Button
                                                                        onClick={() => {
                                                                            const localItems = JSON.parse(localStorage.getItem('items')) || []
                                                                            const result = localItems.filter((e) => e.id !== item.id)
                                                                            localStorage.setItem('items', JSON.stringify(result))
                                                                            arrayHelpers.remove(index)
                                                                        }}
                                                                        icon="close-outline"
                                                                        className="is-danger is-outlined is-size-7"
                                                                        disabled={loading}
                                                                    />
                                                                    <Button
                                                                        onClick={() => showProductEdit({
                                                                            item,
                                                                            arrayHelp: { index, arrayHelpers },
                                                                        })}
                                                                        icon="create-outline"
                                                                        className="is-size-7 is-outlined is-link"
                                                                        disabled={loading}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }}
                                            />

                                            <div className="is-flex mt-6">
                                                <input type="search" className="input" onChange={handleSearch} />

                                                <div className="ml-2">
                                                    <Button onClick={() => showProductCreate(arrayHelpers)} icon="add-outline" className="is-link" disabled={loading} />
                                                    &nbsp;&nbsp;
                                                </div>
                                            </div>

                                            {params.search && (
                                                <Table
                                                    columns={{
                                                        name: 'Наименование товара',
                                                        car: 'Название автомобиля',
                                                        brand: 'Название бренда',
                                                        manufacturer: 'Производитель',
                                                        category: 'Категория',
                                                        code: 'Штрих-код',
                                                        quantity: 'Количество',
                                                        actions: '',
                                                    }}
                                                    items={products.filter((prod) => !values.products.map((p) => p?.id).includes(prod?.id))}
                                                    renderItem={(item) => {
                                                        console.log('item')

                                                        return (
                                                            <tr key={item?.id}>
                                                                <td>{item?.name}</td>
                                                                <td>
                                                                    {item?.car.map((car) => (
                                                                        <span className="is-light tag is-link mr-2 mb-2">{car.name}</span>
                                                                    ))}
                                                                </td>
                                                                <td>{item?.selectBrand?.name}</td>
                                                                <td>{item?.selectManufacturer?.name}</td>
                                                                <td className="is-size-6">{item?.category ? item.selectCategory.name : 'Другое'}</td>

                                                                <td>{item?.barcode}</td>
                                                                <td>
                                                                    {item?.count} {getMeasurement(item?.measurement)}
                                                                </td>
                                                                <td style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                                                    <Button onClick={() => showProductEdit({ item })} icon="create-outline" className="is-size-7 is-outlined is-link " disabled={loading} />
                                                                    <Button onClick={() => showDeleteProduct(item)} icon="trash-outline" className="is-size-7 is-danger is-outlined" disabled={loading} />

                                                                    {item?.paymentType === values.currency ? (
                                                                        <Button
                                                                            onClick={() => {
                                                                                const localItems = JSON.parse(localStorage.getItem('items')) || []
                                                                                localItems.push(item)
                                                                                localStorage.setItem('items', JSON.stringify(localItems))
                                                                                arrayHelpers.push(item)
                                                                            }}
                                                                            icon="add-outline"
                                                                            className="is-size-7 is-outlined is-success"
                                                                            disabled={loading}
                                                                        />
                                                                    ) : (
                                                                        <span className="has-text-danger">Выберите другую валюту для добавления</span>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        )
                                                    }}
                                                />
                                            )}
                                        </>
                                    )
                                }}
                            />

                            <div className="my-4">
                                <div className="is-pulled-left">
                                    <span className="tag is-large is-link is-light mr-2">Общее количество: {totals.totalQuantity.toFixed(2)}</span>
                                    <span className="tag is-large is-link is-light mr-2">Сумма прихода: {totals.totalPurchaseSum.toFixed(2)}</span>
                                    <span className="tag is-large is-link is-light">Сумма продаж: {totals.totalSaleSum.toFixed(2)}</span>
                                </div>
                                <div className="is-pulled-right">
                                    <Button onClick={onCancel} icon="close-outline" text="Отмена" className="is-danger" disabled={loading} />
                                    &nbsp;&nbsp;
                                    <Button text="Сохранить" icon="add-outline" type="submit" className="is-success" disabled={loading} />
                                </div>
                            </div>

                            <br />
                        </Card>
                    </Form>
                )
            }}
        </Formik>
    )
}

const styles = StyleSheet.create({
    modal: {
        width: 1000,
    },
})
